@import "./variables";

*,
*:before,
*:after {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    min-height: 100%;

    font-size: 30px;
    font-family: $lato;
    color: $text-color;

    background-attachment: fixed;
}

main,
aside,
dl,
dd {
    display: block;
}

#root {
    margin: 0;
    position: relative;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

input[type="text"] {
    font-family: $lato;
    font-weight: bold;
    font-size: 22px;
    color: $color3;
}

h1 {
    font-size: 30px;
    font-family: $lato;
    font-weight: bold;
    text-align: center;
    color: $color4;
    margin: 0 auto;
}

h2 {
    font-family: $lato;
    font-weight: bold;
    font-size: 60px;
    color: $color2;
    text-align: center;
}

p {
    font-size: 30px;
    font-family: $lato;
    max-width: 100%;
}

form {
    margin: 0;
}

body.with-cookies {
    padding-bottom: 300px;
}

.button {
    $buttonHeight: 2em;

    background: $color5;
    color: $color1;
    text-decoration: none;
    font-family: $lato;
    font-weight: bold;
    font-size: 30px;
    padding: 0;
    display: inline-block;
    position: relative;
    line-height: $buttonHeight;
    padding: 0 calc($buttonHeight / 2);

    // margin-left: 1em;
    // margin-right: 1em;

    border: none;
    cursor: pointer;

    border-radius: 1em;

    &:focus,
    &:hover {
            background-color: $color2;
            color: $color1;
    }
}

.button-secondary {
    @extend .button;
    $border-size: 2px;

    background: none;
    border: solid $border-size $color5;
    color: $color4;
    position: relative;

    &:focus,
    &:hover {
        background: $color1;
        border-color: $color2;
        color: $color4;
    }
}

.button-big-blue {
    @extend .button;
    font-size: 46px;
}

a {
    color: $color4;
    text-decoration: none;

    &:hover {
        color: $color7;
    }
}

/* ========= */

legend {
    padding: 0;
    display: table;
}
fieldset {
    border: 0;
    padding: 0.01em 0 0 0;
    margin: 0;
    min-width: 0;
}
body:not(:-moz-handler-blocked) fieldset {
    display: table-cell;
}
