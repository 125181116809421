$color1: #fff8e6;
$color2: #f29100;
$color3: #cd1719;
$color4: #5D4420;
$color5: rgb(0,111,164);
$color6: #FBD9A2;
$color7: #e41b13;
$color8: #45B286;


$text-color: $color4;

$lato: 'Lato', sans-serif;
